import fetch from './fetch'
import qs from 'qs'

const EE_API = process.env.EE_API

//获取任务状态
export const getInfo = () =>
  fetch({
    url: `${EE_API}/api/www/v1/event`,
    method: 'GET',
    withCredentials: true,
  })

//触发任务
export const postToTask = (data) =>
  fetch({
    url: `${EE_API}/api/www/v1/event`,
    method: 'post',
    withCredentials: true,
    data: qs.stringify(data),
  })
