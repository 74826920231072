import React, { useEffect, useCallback, useState, useRef, memo, useImperativeHandle, forwardRef } from 'react'
import * as styles from './index.module.less'
import classnames from 'classnames'
import videojs, { VideoJsPlayer } from 'video.js'
import 'video.js/dist/video-js.css'

const videoJsOptions = {
  // autoplay: true,
  controls: true,
  // playbackRates: [0.5, 1, 1.5, 2, 4]
}

const onContextMenuFun = (e: any) => {
  e.preventDefault()
}

/**
 *
 * @param {Object} videoOpt @requires - 必须，video 选项,参考videojs官网
 * @param {ref} fuRef @requires - 必须，父组件中子组件的refId
 *
 */
const Videoplayer: React.FC = forwardRef(
  ({ json, className, videoOpt, fuRef, onClose, showLoadTip = false }: any, ref) => {
    const [videoEl, setVideoEl] = useState(null)
    const [videoJsObj, setVideoJsObj] = useState<VideoJsPlayer>()
    const [visiblyFlag, setVisiblyFlag] = useState(true)
    // video实例
    const videoRef = useCallback((node) => {
      setVideoEl(node)
    }, [])
    // 外层包裹实例,用来显示或者隐藏
    const videoWrap = useRef(null)
    // body ref

    // 初始化video组件
    useEffect(() => {
      if (!videoEl) {
        return
      }
      const player: any = videojs(videoEl, {
        ...videoJsOptions,
        ...{
          ...videoOpt,
          sources: videoOpt?.sources,
        },
      })
      if (showLoadTip) {
        player.on('loadstart', () => {
          // 隐藏默认的加载动画
          player.loadingSpinner.contentEl().innerHTML =
            "<span style='position:absolute;top:50px;left:50%;transform:translateX(-50%);color:#fff;white-space:nowrap'>加载中</span>"
        })
      }
      setVideoJsObj(player)
    }, [videoEl])

    // 关闭视频
    const closeVideo = () => {
      // @ts-ignore
      videoWrap.current.style.display = 'none'
      setVisiblyFlag(true)
      onClose && onClose()
      if (videoEl) {
        // @ts-ignore
        videoJsObj?.pause()
      }
    }
    // // 启用视频
    useImperativeHandle(fuRef, () => ({
      playVideo: () => {
        // @ts-ignore
        videoWrap.current.style.display = 'block'
        setVisiblyFlag(false)
        videoJsObj?.play()
      },
      // 动态切换视频链接
      changeSource: (data) => {
        videoJsObj?.src(data)
        videoJsObj?.load()
      },
    }))

    const videoDiv = (
      <div className={classnames(styles.videoWrap, { '!hidden': visiblyFlag })}>
        <div className={classnames(styles.videoContainer, className)} ref={videoWrap}>
          <span className={styles.close} onClick={closeVideo}>
            ✖️
          </span>
          <div data-vjs-player>
            <video
              controlsList="nodownload"
              onContextMenu={onContextMenuFun}
              ref={videoRef}
              className="video-js vjs-big-play-centered"
            />
          </div>
        </div>
      </div>
    )
    // return bodyRef.current ? ReactDom.createPortal(videoDiv, bodyRef.current) : null;
    return videoDiv
  },
)

export default memo(Videoplayer) as any
