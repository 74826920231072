import React, { useState, useEffect, useContext } from 'react';
import { providerContext } from 'components/Provider';
import classnames from 'classnames';
import * as styles from './index.module.less';

const ScrollBarShowLayout = ({ childrenEle, limitWidth, innerH, getCurInnerH, getUser, className }: any): JSX.Element => {
  let { user } = useContext(providerContext)
  const [showScroll, setShowScroll] = useState(false);
  const [innerHeight, setInnerHeight] = useState(innerH)
  const handleResize = () => {
    const curH = window.innerHeight
    setInnerHeight(curH);
    getCurInnerH && getCurInnerH(curH);
    const clientW = document.body.clientWidth;
    if (clientW < limitWidth) {
      setShowScroll(true)
    } else {
      setShowScroll(false)
    }
  }

  useEffect(() => {
    getUser && getUser(user)
  }, [user])

  useEffect(() => {
    //进入页面需要执行一次
    handleResize()
    //监听页面窗口大小变化
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  return (
    <div className={classnames(className, showScroll ? styles.scrollContianer : null)} style={{height: `${innerHeight}px`}}>
     {childrenEle}
    </div>
  )
    

}

export default ScrollBarShowLayout;